import { CellProps } from 'react-table'
import { PartialRequestDto } from '@strafos/common'
import Box from '@material-ui/core/Box'
import Typography from '@app/components/atoms/Typography/Typography'

import RateReview from '@material-ui/icons/RateReview'
import React from 'react'
import styled from 'styled-components'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'

export const RequestedByCell = ({ cell }: CellProps<PartialRequestDto, undefined>) => (
  <Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row">
    <StyledBox width={8} bgcolor={cell.row.original.client?.color || 'transparent'} marginRight={0.8} height="35px" />
    <Box display="flex" flexDirection="column">
      <Typography variant="content">{cell.row.original.contact_person_name}</Typography>
      <Typography variant="subtitle">{cell.row.original.client_name}</Typography>
    </Box>
    {cell.row.original.client?.note && (
      <Tooltip title={<div style={{ fontSize: '16px' }}>{cell.row.original.client.note}</div>} disablePortal={false}>
        <Box display="flex" alignItems="center" marginLeft={1}>
          <StyledRateReviewIcon />
        </Box>
      </Tooltip>
    )}
  </Box>
)

const StyledBox = styled(Box)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`

const StyledRateReviewIcon = styled(RateReview)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 18px;
`
