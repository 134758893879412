import React from 'react'
import { useTranslation } from 'react-i18next'

import Badge, { BadgeVariants } from '@app/components/atoms/Badge/Badge'

import { ChatMessageTypes, OfferStatuses, ChatMessageDto } from '@strafos/common'
import { theme } from '@app/theme'
import { getOfferColor } from '@app/utils/stylingUtils'

interface ChatMessageBadgeProps {
  message: ChatMessageDto
}

const ChatMessageBadge = ({ message }: ChatMessageBadgeProps) => {
  const { t } = useTranslation()

  if (message.type === ChatMessageTypes.UserMessage) {
    return (
      <Badge
        color={theme.colors.lightBlue}
        label={t('molecules.ChatMessage.userMessage')}
        variant={BadgeVariants.Regular}
      />
    )
  }

  if (message.type === ChatMessageTypes.ExternalMessage) {
    return (
      <Badge
        color={theme.colors.grey}
        label={t('molecules.ChatMessage.avinodeMessage')}
        variant={BadgeVariants.Regular}
      />
    )
  }

  if (message.type === ChatMessageTypes.SystemMessage && message.offer_status) {
    const offerColor = getOfferColor(theme, message.offer_status)

    const baseBadgeProps = {
      variant: BadgeVariants.Regular,
      color: offerColor,
    }

    switch (message.offer_status) {
      case OfferStatuses.Quoted:
        return <Badge {...baseBadgeProps} label={t('molecules.ChatMessage.quoted')} />

      case OfferStatuses.Booked:
        return <Badge {...baseBadgeProps} label={t('molecules.ChatMessage.booked')} />

      case OfferStatuses.Declined:
        return <Badge {...baseBadgeProps} label={t('molecules.ChatMessage.declined')} />

      case OfferStatuses.Cancelled:
        return <Badge {...baseBadgeProps} label={t('molecules.ChatMessage.cancelled')} />

      case OfferStatuses.Rejected:
        return <Badge {...baseBadgeProps} label={t('molecules.ChatMessage.rejected')} />

      default:
        return <Badge {...baseBadgeProps} label={t('molecules.ChatMessage.messageWithOfferDefaultText')} />
    }
  }

  return null
}

export default ChatMessageBadge
